/* eslint-disable max-lines */
"use client";

import { usePathname } from "next/navigation";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CART, CHANGE_CURRENCY, CURRENCY_ID } from "../../../constants/common/common";
import { mobileIcons } from "../../../constants/common/headerToolbarConstants";
import { requestViewCart, setIsCartVisible } from "../../../store/slices/cartSlice";
import IconButtonComponent from "../../atoms/iconButton";
import LocationLockStrip from "../../molecules/locationLock/LocationLockStrip";
import { requestToGetCurrency, setCurrencyDrawerVisible } from "../../../store/slices/common/currencyLists";
import Typography from "../../atoms/typography";
import { initiateFus } from "../../../store/slices/common/fus";
import { unlockBody } from "../../../utils/document";
import { requestToFetchFnpCashBalance, requestToFetchFnpLabel } from "../../../store/slices/fnpCash";
import { requestToSavedAddress } from "../../../store/slices/savedAddresses";
import { iconsForSelectedCurrency } from "../../../utils/currency";
import useAnalytics from "../../../analytics/useAnalytics";
import { pageName } from "../../../utils/common";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import isEmpty from "lodash.isempty";
import { GA4_EVENTS, SOCIAL_LOGIN_KEY } from "../../../analytics/gaConstant";
import { setGA4LoginAndSignUp } from "../../../analytics/gaEvents";
import { requestToGetCustomerDetails } from "../../../store/slices/loginSignupSlice";
import { setIsMobileSearchVisibleAction } from "../../../store/slices/commonSlice";
import { getSessionStorage, removeSessionStorage } from "../../../utils/storage/sessionStorageUtil";
import { onClevertapPublish } from "../../../api/common";

const Cart = dynamic(() => import("../cart"), { ssr: false });
const MobileSearch = dynamic(() => import("../mobileSearch"), { ssr: false });
const Drawer = dynamic(() => import("../../molecules/drawer"), { ssr: false });
const CurrencyList = dynamic(() => import("../header/headerToolBar/headerComponents/currencyList"), { ssr: false });

/**
 *
 */
const HeaderPLPAndPDPMobile = ({
  isShowLocationLock,
  headerName,
  totalProducts,
  renderedProducts,
  catalogId = "india",
  // preloadLinks,
  isShowCount,
  isShowIcons = true,
  isIntlMicrosite = false,
}) => {
  const dispatch = useDispatch();
  const { trackEvent, onUserLogin } = useAnalytics();
  const pathname = usePathname();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isShowSearchPopUp, setIsShowSearchPopUp] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_ID);
  const { loginSignUp, loginDetail, details, cartCount, checkLoginStatus, userSelectedCurrency } = useSelector(
    (state) => ({
      loginSignUp: state?.loginSignUp,
      loginDetail: state?.fus?.loginDetail,
      details: state?.plp?.details,
      cartCount: state.cart?.cartCount,
      checkLoginStatus: state.fus.loginDetail?.loggedin,
      userSelectedCurrency: state?.currencyList?.userSelectedCurrency,
    }),
    shallowEqual,
  );

  const totalRenderedProducts = details?.data?.length || renderedProducts;
  const totalNoOfProducts = details?.total || totalProducts;
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const { product } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;

  const pageHeaderMenuData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    menu_name: null,
  };

  // preloadLinks &&
  //   preloadLinks.length > 0 &&
  //   preloadLinks.map((linkItem) => {
  //     if (linkItem.rel === "dns-prefetch") {
  //       ReactDOM.prefetchDNS(linkItem.href);
  //     } else if (linkItem.rel === "preload") {
  //       ReactDOM.preload(linkItem.href);
  //     } else if (linkItem.rel === "preconnect") {
  //       ReactDOM.preconnect(linkItem.href);
  //     }
  //   });

  useEffect(() => {
    if (loginDetail?.loggedin) {
      dispatch(requestToGetCustomerDetails());
    }
  }, [loginDetail?.loggedin]);

  useEffect(() => {
    const isUserLoggedIn = loginDetail?.loggedin && !isEmpty(loginSignUp?.userDetails);
    if (isUserLoggedIn) {
      const userDetails = { ...loginSignUp?.userDetails, userType: loginDetail?.userType };
      const isOAuth = loginDetail?.isOAuthUser;
      const storageItem = !!getSessionStorage(SOCIAL_LOGIN_KEY);
      const eventName = loginDetail?.userType === "Existing" ? GA4_EVENTS.LOGIN : GA4_EVENTS.SIGNUP;
      if (storageItem) {
        const publishCleverTapData = { profile: { identity: userDetails?.emailID } };
        onClevertapPublish(publishCleverTapData);
        const properties = setGA4LoginAndSignUp(userDetails, isOAuth);
        trackEvent(eventName, { ...properties }, ["ga4"]);
        onUserLogin({
          Site: {
            Identity: userDetails?.emailID,
            Email: userDetails?.emailID,
          },
        });
        trackEvent(cleverTapCommonEvents.loginSuccess, { loginSuccess: "Login Success" }, ["clevertap"]);
        removeSessionStorage(SOCIAL_LOGIN_KEY);
      }
    }
  }, [loginSignUp?.userDetails]);

  useEffect(() => {
    dispatch(requestToGetCurrency());
    dispatch(requestToFetchFnpLabel());
    dispatch(initiateFus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (checkLoginStatus) {
      dispatch(requestToSavedAddress());
      dispatch(requestToFetchFnpCashBalance());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoginStatus]);

  useEffect(() => {
    if (userSelectedCurrency?.currency?.id) {
      setSelectedCurrency(userSelectedCurrency?.currency?.id);
    }
  }, [userSelectedCurrency]);

  /**
   * @function // OnChanging th header toolbar icons
   * @returns {void}
   */
  const onTabClick = (tabName) => {
    switch (tabName) {
      case "cart":
        dispatch(requestViewCart());
        dispatch(setIsCartVisible(true));
        trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: "cart" }, [
          "clevertap",
        ]);
        break;
      case "search":
        setIsShowSearchPopUp(true);
        dispatch(setIsMobileSearchVisibleAction(true));
        trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: "Search" }, [
          "clevertap",
        ]);
        break;
      case "currency":
        setIsDropdownVisible(true);
        dispatch(setCurrencyDrawerVisible(true));
        trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: "currency" }, [
          "clevertap",
        ]);
        break;
      default:
        break;
    }
  };
  /** Handle hiding dropdown when currency is selected */
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency); // Update the selected currency
    setIsDropdownVisible(false); // Hide the dropdown
    dispatch(setCurrencyDrawerVisible(false));
    unlockBody();
    onTabClick(null);
  };

  /** */
  const onHandleBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = "/";
    }
  };

  /** */
  const onDrawerClose = () => {
    dispatch(setCurrencyDrawerVisible(false));
    setIsDropdownVisible(false);
  };

  /** */
  const setIconSrc = (url, id) => {
    if (selectedCurrency && id === "currency") {
      return iconsForSelectedCurrency(selectedCurrency);
    }
    return url;
  };

  return (
    <>
      <div className={` left-0 top-0 z-50 flex w-full flex-col bg-white-900`}>
        <div className="flex min-h-48 items-center justify-between gap-16 px-16 py-6">
          <div className="flex w-[60%] max-w-fit flex-1 items-center gap-8 pr-32">
            {!isIntlMicrosite && (
              <div className="flex-none" onClick={onHandleBack}>
                <IconButtonComponent
                  labelColor="w-full font-normal"
                  iconSrc="/icons/left-arrow.svg"
                  classes="py-2"
                  imgHeight={24}
                  imgWidth={24}
                  isPriority={false}
                />
              </div>
            )}
            {headerName && (
              <div className="flex w-full flex-col">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-16 font-600 capitalize text-fnp-500">
                  {headerName}
                </div>
                {isShowCount && totalRenderedProducts ? (
                  <div className="text-12 font-500 capitalize text-grey-400">{`${totalRenderedProducts}/${totalNoOfProducts} products`}</div>
                ) : null}
              </div>
            )}
          </div>
          {isShowIcons && (
            <div className="flex w-[40%] flex-1 items-center justify-end gap-8">
              {mobileIcons?.map((item, index) => (
                <div
                  key={index}
                  className={`relative flex cursor-pointer items-center justify-center`}
                  tabIndex="0"
                  id={item?.id}
                  onClick={() => onTabClick(item?.id)}
                >
                  {item?.label === CART && cartCount > 0 && (
                    <span className="absolute  bottom-13 left-20 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-r from-notification-secondary to-notification-primary p-4 text-12 text-white-900">
                      {cartCount}
                    </span>
                  )}
                  <IconButtonComponent
                    labelColor="w-full font-normal"
                    classes="py-2 flex flex-col justify-center items-center gap-2"
                    imgWidth={item?.width}
                    iconSrc={setIconSrc(item?.url, item?.id)}
                    isPriority={false}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {isShowLocationLock && <LocationLockStrip />}
        <Cart isMobile={true} />
      </div>
      {isShowSearchPopUp && (
        <MobileSearch
          setShowSearchPopUp={setIsShowSearchPopUp}
          isShowSearchSec={isShowSearchPopUp}
          catalogId={catalogId}
        />
      )}
      <Drawer
        position="bottom"
        isOpen={isDropdownVisible}
        onDrawerClose={onDrawerClose}
        styles="px-16 max-h-[90vh] pb-0"
      >
        <div className="flex flex-col gap-8">
          <Typography Tag={"h4"} variant="h6" classes="text-16 font-600 mb-8 px-16">
            {CHANGE_CURRENCY}
          </Typography>
          <div className="mb-16 max-h-[calc(80vh-34px)] overflow-auto rounded-8 border-1" tabIndex="0">
            <CurrencyList setSelectedCurrency={handleCurrencySelect} selectedCurrency={selectedCurrency} />
          </div>
        </div>
      </Drawer>
    </>
  );
};

HeaderPLPAndPDPMobile.propTypes = {
  isShowLocationLock: PropTypes.bool.isRequired,
  headerName: PropTypes.string,
  totalProducts: PropTypes.number,
  renderedProducts: PropTypes.number,
  catalogId: PropTypes.string,
  // preloadLinks: PropTypes.array,
  isShowCount: PropTypes.bool,
  isShowIcons: PropTypes.bool,
  isIntlMicrosite: PropTypes.bool,
};
export default React.memo(HeaderPLPAndPDPMobile);
